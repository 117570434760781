import * as React from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBBadge, MDBCardGroup, MDBTooltip } from 'mdb-react-ui-kit'
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText } from 'mdb-react-ui-kit'
import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBCarouselCaption } from 'mdb-react-ui-kit'
import { StaticImage } from 'gatsby-plugin-image'
import './global.scss'
import './index.scss'
import { Link } from 'gatsby'
import schoolBackground from '../images/school-background.png'
import aboutBackground from '../images/fire-bottom-wide.svg'
import HeadTags from '../components/HeadTags'
import Icon from '../components/Icon'

const IndexPage = () => {
    const aboutApronsElement: any = React.useRef()

    const quotes = [
        { quote: 'Hello? Is it you I’m grilling for?', originalName: 'Lionel Ritchie', name: 'Mom' },
        { quote: 'God only knows what I’d be without food', originalName: 'Beach Boys', name: 'Uncle Tony' },
        { quote: 'I’m gonna make him a burger he can’t refuse', originalName: 'Don Vito Corleone', name: 'Grandpa B' },
        {
            quote: 'Because I’m all about that steak, ‘bout that steak, no tofu',
            originalName: 'Meghan Trainor',
            name: 'Customize For Someone You Love',
        },
    ]

    const handleClickAbout = () => {
        aboutApronsElement?.current?.scrollIntoView({ behavior: 'smooth' })
    }

    function HeaderSection(props: any) {
        const carouselItems = quotes.map((quote: any, index: number) => (
            <MDBCarouselItem className={index === 0 ? 'active' : ''} key={index}>
                <MDBCarouselCaption>
                    <h1>{quote.quote}</h1>
                    <div className="fs-5 text-end strikethrough pe-5">&ndash; {quote.originalName}</div>
                    <div className="fs-5 text-end pe-5 mt-0">&ndash; {quote.name}</div>
                </MDBCarouselCaption>
            </MDBCarouselItem>
        ))

        return (
            <MDBContainer
                fluid
                className="py-4 px-md-4 header-section"
                style={{
                    minHeight: '100vh',
                    backgroundImage: `url(${aboutBackground}), linear-gradient(356deg, rgb(0, 119, 221), rgb(5, 166, 249))`,
                }}
            >
                <MDBRow className="align-items-start">
                    <MDBCol size="3" className="text-start text-white pe-0">
                        <StaticImage src="../images/logos/logo-4c-white.svg" alt="icon" width={325} className="header-image align-middle" />
                    </MDBCol>
                    <MDBCol size="9" className="text-end text-white ms-auto ps-0 mt-4">
                        <MDBBtn color="light" outline size="sm" className="me-2 text-white d-none d-lg-inline-block" onClick={handleClickAbout}>
                            About Our Aprons
                        </MDBBtn>

                        <MDBBtn color="light" outline size="sm" className="me-2 text-white d-none d-lg-inline-block" href="/my-account/orders/">
                            Check on Your Order / Log In
                        </MDBBtn>

                        <Link to="/order">
                            <MDBBtn color="success" size="sm" className="me-3">
                                Customize &amp; Order Your Apron
                            </MDBBtn>
                        </Link>

                        <MDBTooltip tag="span" title="View your cart">
                            <a className="d-none d-md-inline" href="/cart">
                                <Icon icon="shopping-cart" className="fa-lg" />
                                {props.cartItemCount > 0 && (
                                    <MDBBadge color="danger" notification pill>
                                        {props.cartItemCount}
                                    </MDBBadge>
                                )}
                            </a>
                        </MDBTooltip>

                        <h5 className="mt-4">
                            <MDBBadge pill color="primary" light className="shadow-3-strong" style={{ padding: '9px 19px' }}>
                                Free Shipping! (US and Canada)
                            </MDBBadge>
                        </h5>
                    </MDBCol>
                </MDBRow>

                <MDBRow className="justify-content-center">
                    <MDBCol size="md-10" className="text-white">
                        <MDBCarousel className="d-flex" interval={6500}>
                            <MDBCarouselInner className="d-flex align-items-center">{carouselItems}</MDBCarouselInner>
                        </MDBCarousel>

                        <Link to="/order">
                            <MDBBtn color="success" className="btn-xl mt-4 mb-4">
                                <Icon icon="arrow-right" />
                                Customize &amp; Order Your Apron
                            </MDBBtn>
                        </Link>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        )
    }

    function AddYourQuoteHereSection() {
        return (
            <div className="bg-image pb-5">
                <StaticImage src="../images/collage.jpg" alt="apron" layout="fullWidth" />
                <div className="mask px-2">
                    <div className="d-flex justify-content-center align-items-end h-100">
                        <Link to="/order">
                            <MDBBtn color="success" className="mx-auto mb-2 mb-md-4" size="xl">
                                <Icon icon="arrow-right" />
                                Customize an Apron for Someone You Love!
                            </MDBBtn>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }

    function DonateSection() {
        return (
            <MDBContainer fluid className="py-5 px-md-4 donate-section" style={{ backgroundImage: `url(${schoolBackground})` }}>
                <MDBRow className="justify-content-center mx-md-2 mb-4">
                    <MDBCol size="md-6" className="text-start">
                        <div className="fs-2 title">We donate money from every order to a school or to the Red Cross</div>

                        <select className="form-select my-3">
                            <option value="Kenneth Gordon Maplewood School">Kenneth Gordon Maplewood School</option>
                            <option value="Red Cross">Red Cross</option>
                        </select>
                        <div>
                            $3 from every apron order is donated! You pick the charity, we send the money to{' '}
                            <a href="https://kgms.ca/" rel="noreferrer" className="text-decoration-underline">
                                Kenneth Gordon Maplewood School
                            </a>
                            , a school for kids who learn differently (autistic, ADHD, dyslexia, etc) or the{' '}
                            <a href="https://www.redcross.org/" rel="noreferrer" className="text-decoration-underline">
                                Red Cross
                            </a>
                            .
                        </div>
                    </MDBCol>
                    <MDBCol size="md-3" className="d-flex align-items-center">
                        <StaticImage src="../images/school.jpg" alt="school" />
                    </MDBCol>
                </MDBRow>
                <MDBCardGroup className="card-column mx-auto">
                    <MDBCard className="shadow-3-strong">
                        <MDBCardBody>
                            <StaticImage src="../images/icon.png" alt="icon" width={45} className="mb-2 card-logo" />
                            <MDBCardTitle>It's more than a feeding.</MDBCardTitle>
                            <MDBCardText>Have fun customizing & help a good cause.</MDBCardText>
                        </MDBCardBody>
                    </MDBCard>

                    <MDBCard className="mx-sm-3 shadow-3-strong">
                        <MDBCardBody>
                            <StaticImage src="../images/icon.png" alt="icon" width={45} className="mb-2 card-logo" />
                            <MDBCardTitle>Let's talk about steaks, baby!</MDBCardTitle>
                            <MDBCardText>Your favorite Legendary Griller deserves a Legendary Apron!</MDBCardText>
                        </MDBCardBody>
                    </MDBCard>

                    <MDBCard className="shadow-3-strong">
                        <MDBCardBody>
                            <StaticImage src="../images/icon.png" alt="icon" width={45} className="mb-2 card-logo" />
                            <MDBCardTitle>50 ways to sauce your burger.</MDBCardTitle>
                            <MDBCardText>And one great way to raise money for schools in need.</MDBCardText>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCardGroup>
            </MDBContainer>
        )
    }

    function AboutApronsSection(props: any) {
        return (
            <MDBContainer
                ref={props.aboutApronsElement}
                fluid
                className="py-5 px-md-4 about-aprons-section justify-content-center"
                style={{
                    backgroundImage: `url(${aboutBackground}), linear-gradient(356deg, rgb(0, 119, 221), rgb(5, 166, 249))`,
                }}
            >
                <MDBRow className="justify-content-center mb-4">
                    <MDBCol size="md-6" className="text-start text-black">
                        <div className="fs-2 title">About Our Aprons</div>

                        <div className="mt-2">Our aprons are built to last!</div>

                        <div className="my-2">Quality & custom designs for every grill master, cook, and baker in your life.</div>

                        <ul>
                            <li>Durable, 100% cotton, woven drill fabric</li>
                            <li>Water & stain resistant</li>
                            <li>Double center pocket (great for tongs!)</li>
                            <li>Adjustable neck bib with metal buckle</li>
                            <li>Metal rivet fasteners</li>
                        </ul>
                    </MDBCol>
                    <MDBCol size="md-3" className="d-flex align-items-center">
                        <StaticImage src="../images/quality-collage.jpg" alt="apron" className="shadow-3-strong rounded" />
                    </MDBCol>
                </MDBRow>
                <MDBCardGroup className="card-column mx-auto text-white">
                    <MDBCard className="shadow-3-strong gradient-background">
                        <MDBCardBody>
                            <StaticImage src="../images/icon.png" alt="icon" width={45} className="mb-2 card-logo" />
                            <MDBCardTitle>Dimensions</MDBCardTitle>
                            <MDBCardText>
                                <ul>
                                    <li>32.8" Long x 26.7" Hemline</li>
                                    <li>Neck tie 24.6" Long</li>
                                    <li>Unisex & One Size</li>
                                </ul>
                            </MDBCardText>
                        </MDBCardBody>
                    </MDBCard>

                    <MDBCard className="mx-sm-3 shadow-3-strong gradient-background">
                        <MDBCardBody>
                            <StaticImage src="../images/icon.png" alt="icon" width={45} className="mb-2 card-logo" />
                            <MDBCardTitle>Machine washable</MDBCardTitle>
                            <MDBCardText>Cleanup is a breeze. Machine wash in cold water, hang to dry, and you are good to go for your next BBQ!</MDBCardText>
                        </MDBCardBody>
                    </MDBCard>

                    <MDBCard className="shadow-3-strong gradient-background">
                        <MDBCardBody>
                            <StaticImage src="../images/icon.png" alt="icon" width={45} className="mb-2 card-logo" />
                            <MDBCardTitle>Available Colors</MDBCardTitle>
                            <MDBCardText>
                                <ul>
                                    <li>Raven Black</li>
                                    <li>Royal Blue</li>
                                    <li>Custom colors available for large corporate orders</li>
                                </ul>
                            </MDBCardText>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCardGroup>
            </MDBContainer>
        )
    }

    function Footer() {
        return (
            <MDBContainer fluid className="py-3 footer-section text-white medium-color">
                <MDBRow>
                    <MDBCol>Copyright {new Date().getFullYear()} &copy; Legendary Aprons</MDBCol>
                </MDBRow>
                <MDBRow className="mt-2">
                    <MDBCol>
                        <a href="/privacy-policy/" className="text-white medium-color">
                            Privacy Policy
                        </a>
                        <span className="mx-3">|</span>
                        <a href="/terms" className="text-white medium-color">
                            Terms &amp; Conditions
                        </a>
                        <span className="mx-3">|</span>
                        <a href="/terms" className="text-white medium-color">
                            Refunds &amp; Returns Policy
                        </a>
                        <span className="mx-3">|</span>
                        <a href="mailto:hello@legendaryaprons.com" className="text-info">
                            hello@legendaryaprons.com
                        </a>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        )
    }

    return (
        <main className="landing-page">
            <HeadTags />
            <HeaderSection />
            <AddYourQuoteHereSection />
            <DonateSection />
            <AboutApronsSection aboutApronsElement={aboutApronsElement} />
            <Footer />
        </main>
    )
}

export default IndexPage
